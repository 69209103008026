<template>
	<div class="grid dashboard">
		<template v-for="item in mainData" :key="item.sira">
			<CrmDataTablePanel :label="item.name" :baseEntityName="item.baseentityname" :options="item.options" :isHeaderVisible="true" :isNewButtonVisible="true" :genislik="item.genislik" :gosterilecekSatirAdedi="item.gosterilecekSatirAdedi"  />
		</template>
    </div>

</template>

<script>
import CrmService from "../service/CrmService";

export default {
	data() {
		return {
			mainData: [],
		}
	},
	async created() {
		this.crmService = new CrmService();

		if (this.profileData) {
			let data = await this.crmService.getPanoModulleri();
			debugger;
			if (data.jsonData.length > 0) {
				this.mainData = data.jsonData;

				for (let index = 0; index < this.mainData.length; index++) {
					this.mainData[index]["options"] = JSON.parse(this.mainData[index]["options"]);

					if (this.mainData[index]["gorunumId"] != undefined) {
						this.mainData[index]["options"].forceSavedQueryId = this.mainData[index]["gorunumId"];
					}
				}
			}
		}
	},
	methods: {
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		},
    },
}
</script>

<style lang="scss" scoped>

</style>

<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-6">
				<div class="card">
					<EntityHeader label="FİRMA" entityName="account"></EntityHeader>
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="firmaadi">Firma Adı <span style="color:red">*</span></label>
								<InputText id="firmaadi" type="text" v-model="mainData.firmaAdi" :disabled="isDisabled"/>
								<span v-if="v$.mainData.firmaAdi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaAdi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="yetkiliKisi" v-model="mainData.yetkiliKisiName" ref="entity_yetkilikisi" label="Yetkili Kişi"
									entityName="contact" nameField="fullname" secondNameField="bm_unvan" secondNameFieldType="picklist" :state="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="{'Value': entityId}"
									@itemSelected="yetkiliKisiSelected = $event" @itemCleared="yetkiliKisiSelected = null"></EntityLookup>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="telefon1">Telefon 1 <span style="color:red">*</span></label>
								<InputMask id="telefon1" v-model="mainData.telefon1" mask="(999) 999-9999" :disabled="isDisabled" />
								<span v-if="v$.mainData.telefon1.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.telefon1.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="telefon2">Telefon 2</label>
								<InputMask id="telefon2" v-model="mainData.telefon2" mask="(999) 999-9999" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="websitesi">Web Sitesi</label>
								<span class="flex">
									
									<div class="flex-grow-1 flex">
										<InputText id="websitesi" type="text" v-model="mainData.webSitesi" :disabled="isDisabled" />
									</div>
									<div class="flex-none flex">
										<Button icon="pi pi-link" @click="OnClickWebSite()"  />
									</div>
								</span>
							</div>
						</div>						
						<div class="col-4">
							<div class="field p-fluid">
								<label for="eposta">E-posta Adresi</label>
								<InputText id="eposta" type="text" v-model="mainData.epostaAdresi1" :disabled="isDisabled" />
								<span v-if="v$.mainData.epostaAdresi1.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.epostaAdresi1.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="faks">Faks</label>
								<InputMask id="telefon2" v-model="mainData.fax" mask="(999) 999-9999" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="bultenGonderilecekmi">Bülten Gönderilecek mi?</label><br/>
								<InputSwitch id="bultenGonderilecekmi" v-model="mainData.bultenGonderilecekmi" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-6">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">İLGİLİ KİŞİLER</h5>
						</div>
						<div style="height:39px">
							<Button icon="pi pi-plus" class="p-button-secondary" label="İlgili Kişi Ekle" @click="IlgiliKisiEkle()" :disabled="isDisabled"  />
						</div>
					</div>

					<CrmDataTable :key="CrmDataTable_contact_key" baseEntityName="contact" :options="CrmDataTable_contact_options" :rows="5"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
				</div>
			</div>
		</div>
		<br/>
		<Accordion>
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">MÜŞTERİ BİLGİLERİ</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Bölge <span style="color:red">*</span></label>
							<Dropdown id="talepturu" v-model="mainData.bolgeId" :options="SM_Bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :showClear="true" :disabled="isDisabled" />
							<span v-if="v$.mainData.bolgeId.$error && submitted">
								<span id="email-error" v-for="(error, index) of v$.mainData.bolgeId.$errors" :key="index">
									<small class="p-error">{{error.$message}}</small>
								</span>
							</span>
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="calisanSayisi">Çalışan Sayısı</label>
							<InputNumber id="calisanSayisi" v-model="mainData.calisanSayisi" mode="decimal" locale="tr-TR" :minFractionDigits="0" showButtons :min="0" :max="15000" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmatipi">Firma Tipi</label>
							<Dropdown id="firmatipi" v-model="mainData.firmaTipiId" :options="SM_FirmaTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Firma Tipi" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="kategori">Kategori</label>
							<Dropdown id="kategori" v-model="mainData.kategoriId" :options="SM_Kategori" optionLabel="Value" optionValue="AttributeValue" placeholder="Kategori" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="ilgilimarka">İlgili Marka</label>
							<Dropdown id="ilgilimarka" v-model="mainData.ilgiliMarkaId" :options="SM_IlgiliMarka" optionLabel="Value" optionValue="AttributeValue" placeholder="İlgili Marka" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Kullandığı Tezgah</label>
							<Dropdown id="ilgilimarka" v-model="mainData.kullandigiTezgahId" :options="SM_KullandigiTezgah" optionLabel="Value" optionValue="AttributeValue" placeholder="Kullandığı Tezgah" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Endüstri</label>
							<Dropdown id="ilgilimarka" v-model="mainData.endustriId" :options="SM_Endustri" optionLabel="Value" optionValue="AttributeValue" placeholder="Endüstri" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="potansiyel3d">3D Potansiyeli</label>
							<Dropdown id="potansiyel3d" v-model="mainData.potansiyel3d" :options="SM_3dPotansiyeli" optionLabel="Value" optionValue="AttributeValue" placeholder="3D Potansiyeli" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
		<br/>
		<Accordion :activeIndex="0">
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">ADRES BİLGİLERİ</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adresAdi">Adres Adı</label>
							<InputText id="adresAdi" type="text" v-model="mainData.adresAdi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-9">
						<div class="field p-fluid">
							<label for="cadde1">Adres</label>
							<InputText id="cadde1" type="text" v-model="mainData.cadde1" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="semtbolge">İlçe</label>
							<InputText id="semtbolge" type="text" v-model="mainData.semtBolge" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="sehir">Şehir <span style="color:red">*</span></label>
							<Dropdown id="sehir" v-model="mainData.sehirId" :options="SM_Sehir" optionLabel="Value" optionValue="AttributeValue" placeholder="Şehir" :showClear="true" :filter="true" :disabled="isDisabled" />
							<span v-if="v$.mainData.sehirId.$error && submitted">
								<span id="email-error" v-for="(error, index) of v$.mainData.sehirId.$errors" :key="index">
									<small class="p-error">{{error.$message}}</small>
								</span>
							</span>
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Posta Kodu</label>
							<InputText id="firmaadi" type="text" v-model="mainData.postaKodu" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="ulke">Ülke</label>
							<Dropdown id="ulke" v-model="mainData.ulkeId" :options="SM_Ulke" optionLabel="Value" optionValue="AttributeValue" placeholder="Ülke" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3" v-if="mainData.sehirId == 34">
						<div class="field p-fluid">
							<label for="istanbulandavr">İstanbul Anadolu Avrupa</label>
							<Dropdown id="istanbulandavr" v-model="mainData.istanbulAndAvrId" :options="SM_IstanbulAnadoluAvrupa" optionLabel="Value" optionValue="AttributeValue" placeholder="İstanbul Anadolu Avrupa" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
		<br/>
		<Accordion>
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">ADRES BİLGİLERİ 2</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_adresAdi">Adres 2: Ad</label>
							<InputText id="adr2_adresAdi" type="text" v-model="mainData.adres2_adresAdi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-9">
						<div class="field p-fluid">
							<label for="adr2_cadde1">Adres 2: Adres</label>
							<InputText id="adr2_cadde1" type="text" v-model="mainData.adres2_cadde1" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_ilce">Adres 2: İlçe</label>
							<InputText id="adr2_ilce" type="text" v-model="mainData.adres2_ilce" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_sehir">Adres 2: Şehir</label>
							<Dropdown id="adr2_sehir" v-model="mainData.sehir2Id" :options="SM_Sehir2" optionLabel="Value" optionValue="AttributeValue" placeholder="Şehir" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_postaKodu">Adres 2: Posta Kodu</label>
							<InputText id="adr2_postaKodu" type="text" v-model="mainData.adres2_postaKodu" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_telefon1">Adres 2: Telefon 1</label>
							<InputMask id="adr2_telefon1" type="text" mask="(999) 999-9999" v-model="mainData.adres2_telefon1" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_telefon2">Adres 2: Telefon 2</label>
							<InputMask id="adr2_telefon2" type="text" mask="(999) 999-9999" v-model="mainData.adres2_telefon2" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_fax">Adres 2: Faks</label>
							<InputMask id="adr2_fax" type="text" mask="(999) 999-9999" v-model="mainData.adres2_fax" :disabled="isDisabled" />
						</div>
					</div>
					
					
				</div>
			</AccordionTab>
		</Accordion>
		<br/>
		<Accordion>
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">EK BİLGİLER ve NOTLAR</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Vergi Dairesi</label>
							<InputText id="firmaadi" type="text" v-model="mainData.vergiDairesi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Vergi Numarası</label>
							<InputText id="firmaadi" type="text" v-model="mainData.vergiNumarasi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="paymenttermscode">Ödeme Koşulları</label>
							<Dropdown id="paymenttermscode" v-model="mainData.odemeKosullariId" :options="SM_paymenttermscode" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme Koşulları" :showClear="true" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Kullandığı Yağ</label>
							<Dropdown id="talepturu" v-model="mainData.kullandigiYagId" :options="SM_KullandigiYag" optionLabel="Value" optionValue="AttributeValue" placeholder="Kullandığı Yağ" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Yağ Modeli</label>
							<InputText id="firmaadi" type="text" v-model="mainData.yagModeli" :disabled="isDisabled" />
						</div>
					</div>
				</div>
				<div class="grid pt-3">
					<div class="col-12">
						<div class="field p-fluid">
							<label for="firmaadi">Açıklama</label>
							<Textarea id="firmaadi" rows="3" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
						</div>
					</div>

					<div class="col-12">
						<div class="field p-fluid">
							<Annotation objectTypeCode="1" logicalName="account" :objectId="entityId" ></Annotation>
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
		<br/>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<TabView :scrollable="true" lazy>
						<!-- SATIŞ PROJELERİ -->
						<TabPanel v-if="isSatisProjeleriYetki">
							<template #header>
								<div class="cyan-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-shopping-cart"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SATIŞ PROJELER</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.SatisProjeAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.SatisProjeAdetAcik}} Açık Proje</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_opportunity_key" baseEntityName="opportunity" :options="CrmDataTable_opportunity_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>
						
						<!-- SİPARİŞLER -->
						<TabPanel v-if="isSiparislerYetki">
							<template #header>
								<div class="pink-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-dollar"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SİPARİŞLER</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.SiparisAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.SiparisSonTarih}} Son Sipariş</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_salesorder_key" baseEntityName="salesorder" :options="CrmDataTable_salesorder_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- TEZGAHLAR -->
						<TabPanel v-if="isTezgahlarYetki">
							<template #header>
								<div class="green-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-dollar"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">TEZGAHLAR</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.TezgahAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.TezgahKurulumSonTarih}} Son Kurulum</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_bm_unite_key" baseEntityName="bm_unite" :options="CrmDataTable_bm_unite_options" :rows="20"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- AKTİVİTELER -->
						<TabPanel>
							<template #header>
								<div class="deeporange-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-users"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">AKTİVİTELER</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.AktiviteAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.AktiviteSonTarih}} Son İletişim</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_appointment_key" baseEntityName="appointment" :options="CrmDataTable_appointment_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />

							<CrmDataTable :key="CrmDataTable_phonecall_key" baseEntityName="phonecall" :options="CrmDataTable_phonecall_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- SERVİS TALEPLERİ -->
						<TabPanel v-if="isServisTalebiYetki">
							<template #header>
								<div class="purple-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">SERVİS TALEPLERİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.ServisTalebiAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.ServisTalebiAcik}} Açık Talep</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_incident_key" baseEntityName="incident" :options="CrmDataTable_incident_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- YEDEK PARÇA TEKLİF SİPARİŞ -->
						<TabPanel v-if="isYedekParcaTeklifSiparis">
							<template #header>
								<div class="yellow-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">Y.P. TEKLİF/SİP.</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.YedekParcaTeklifSiparisAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.YedekParcaTeklifSiparisAcik}} Yeni / Bekleyen</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_bm_yedekparcateklifsiparis_key" baseEntityName="bm_yedekparcateklifsiparis" :options="CrmDataTable_bm_yedekparcateklifsiparis_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- SERVİS RAPORLARI -->
						<TabPanel v-if="isServisTalebiYetki">
							<template #header>
								<div class="pink-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">SERVİS RAPORLARI</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.ServisRaporuAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.ServisRaporuAcik}} Açık Rapor</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_serviceappointment_key" baseEntityName="serviceappointment" :options="CrmDataTable_serviceappointment_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- BAKIM SÖZLEŞMELERİ -->
						<TabPanel v-if="isSozlesmelerYetki">
							<template #header>
								<div class="blue-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">BAKIM SÖZLEŞMELERİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.BakimSozlesmesiAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.BakimSozlesmesiAcik}} Bekleyen Sözleşme</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_bm_sozlesmeler_key" baseEntityName="bm_sozlesmeler" :options="CrmDataTable_bm_sozlesmeler_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- RAKİP TEZGAHLAR -->
						<TabPanel>
							<template #header>
								<div class="green-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">RAKİP TEZGAHLAR</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.RakipTezgahAdet}} adet</span>
											<span class="overview-status p-1 fs-small">.</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_bm_rakipunite_key" baseEntityName="bm_rakipunite" :options="CrmDataTable_bm_rakipunite_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- OTOMASYON PROJESİ -->
						<TabPanel>
							<template #header>
								<div class="green-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">OTOMASYON PROJESİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.OtomasyonProjesiAdet}} adet</span>
											<span class="overview-status p-1 fs-small">.</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_bm_otomasyonprojesi_key" baseEntityName="bm_otomasyonprojesi" :options="CrmDataTable_bm_otomasyonprojesi_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>
					</TabView>
				</div>
			</div>
			
		</div>

		<br/>

		<div v-if="false" class="grid">
			<div class="col-12">
				<div class="card">
					<h5>Tezgahlar</h5>
					<Carousel :value="products" :numVisible="4" :numScroll="3" :circular="false" :responsiveOptions="carouselResponsiveOptions">
						<template #item="product">
							<div class="product-item">
								<div class="product-item-content">
									<div class="mb-5">
										<Image :src="'demo/images/product/' + product.data.image" :alt="product.data.name" width="200" preview />
									</div>
									<div>
										<h6 class="mb-1">
											{{ product.data.name }}
										</h6>
										<div class="grid">
											<div class="col-6">
												<b>PO No:</b> FLO563
											</div>
											<div class="col-6">
												<b>Seri No:</b> 263521
											</div>
										</div>

										<span :class="'product-badge status-' + product.data.inventoryStatus.toLowerCase()">KURULDU</span>
										<div class="car-buttons mt-5">
											<Button type="button" class="p-button p-button-rounded mr-2" icon="pi pi-search"></Button>
											<Button type="button" class="p-button-success p-button-rounded mr-2" icon="pi pi-star-fill"></Button>
											<Button type="button" class="p-button-help p-button-rounded" icon="pi pi-cog"></Button>
										</div>
									</div>
								</div>
							</div>
						</template>
					</Carousel>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import user from '../store/user';

export default
 {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 1,
			ENTITY_SUBJECT: "Firmalar",
			mainData: {},
			ozetData: {},

			CrmDataTable_contact_key: 0,
			CrmDataTable_contact_options: {
				searchAttributes: ["fullname", "telephone1", "emailaddress1", "mobilephone"],
				filterParent: [
					{ 'name': 'parentcustomerid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '00000000-0000-0000-00AA-000010001210',
				views: [
					{ 'viewName': 'İlgili Kişiler', 'savedQueryId': '00000000-0000-0000-00AA-000010001210' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_opportunity_key: 0,
			CrmDataTable_opportunity_options: {
				searchAttributes: ["ordernumber", "customeridname", "bm_frmno", "bm_urunidname"],
				filterParent: [
					{ 'name': 'customerid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				customFormat: [
					{ 'logicalName': 'salesorder', 'attributeName' : 'bm_geneltoplam', 'format' : 'n0'},
				],
				forceSavedQueryId: '00000000-0000-0000-00AA-000010003001',
				views: [
					{ 'viewName': 'Açık Projeler', 'savedQueryId': 'DC47E77C-3CCB-ED11-BC97-000C2920F782' },
					{ 'viewName': 'Kazanılan Projeler', 'savedQueryId': '13AEE8DF-3CCB-ED11-BC97-000C2920F782' },
					{ 'viewName': 'Kaybedilen Projeler', 'savedQueryId': '9938E0E7-3CCB-ED11-BC97-000C2920F782' },
					{ 'viewName': 'Tüm Projeler', 'savedQueryId': '5F5DC1F4-3CCB-ED11-BC97-000C2920F782' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_salesorder_key: 0,
			CrmDataTable_salesorder_options: {
				searchAttributes: ["ordernumber", "customeridname", "bm_frmno", "bm_urunidname"],
				filterParent: [
					{ 'name': 'customerid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '71F7DA6E-2772-4097-89D1-3670FF8348BB',
				views: [
					{ 'viewName': 'Siparişler', 'savedQueryId': '71F7DA6E-2772-4097-89D1-3670FF8348BB' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_bm_unite_key: 0,
			CrmDataTable_bm_unite_options: {
				searchAttributes: ["bm_kurulummusteriidname", "bm_frmno", "bm_serino", "bm_po", "bm_urunidname", "bm_siparisnoidname", "bm_teklifnoidname", "bm_urunidname", "bm_markaname"],
				filterParent: [
					{ 'name': 'bm_kurulummusteriid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '6BB03829-45CB-ED11-BC97-000C2920F782',
				views: [
					{ 'viewName': 'Etkin Tezgah', 'savedQueryId': '6BB03829-45CB-ED11-BC97-000C2920F782' },
					{ 'viewName': 'Etkin Olmayan Tezgahlar', 'savedQueryId': 'A5595F1D-8F13-46EC-8654-C27E7663D901' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_appointment_key: 0,
			CrmDataTable_appointment_options: {
				searchAttributes: ["subject", "regardingobjectidname"],
				filterParent: [
					{ 'name': 'regardingobjectid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				views: [
					{ 'viewName': 'Randevular', 'savedQueryId': 'C9E756D7-10D7-ED11-AFA0-000C2920F782' },
				],
				forceSavedQueryId: 'C9E756D7-10D7-ED11-AFA0-000C2920F782',
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_phonecall_key: 0,
			CrmDataTable_phonecall_options: {
				searchAttributes: ["subject", "regardingobjectidname"],
				filterParent: [
					{ 'name': 'regardingobjectid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				views: [
				{ 'viewName': 'Telefon Görüşmeleri', 'savedQueryId': '89D8696F-14D7-ED11-AFA0-000C2920F782' },
				],
				forceSavedQueryId: '89D8696F-14D7-ED11-AFA0-000C2920F782',
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_incident_key: 0,
			CrmDataTable_incident_options: {
				searchAttributes: ["customeridname", "bm_uniteidname", "productidname", "title", "ticketnumber"],
				filterParent: [
					{ 'name': 'customerid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '00000000-0000-0000-00AA-000010001030',
				preventSystemViews: false,
				preventUserViews: true
			},

			CrmDataTable_bm_yedekparcateklifsiparis_key: 0,
			CrmDataTable_bm_yedekparcateklifsiparis_options: {
				searchAttributes: ["bm_teklifbasligi", "bm_teklifno", "bm_siparisno", "bm_musterigercekidname", "bm_urunidname", "bm_uniteidname", "bm_musterireferansno" ],
				filterParent: [
					{ 'name': 'bm_musterigercekid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '85EC6CFB-AC75-E211-9AD9-E61F135C9177',
				preventSystemViews: false,
				preventUserViews: true
			},

			CrmDataTable_serviceappointment_key: 0,
			CrmDataTable_serviceappointment_options: {
				searchAttributes: ["bm_kod", "bm_servistalebiidname", "bm_uniteidname", "bm_firmaidname", "bm_urunidname", "subject"],
				filterParent: [
					{ 'name': 'bm_firmaid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '03F03273-4BCB-ED11-BC97-000C2920F782',
				views: [
					{ 'viewName': 'Tüm Servis Raporları', 'savedQueryId': '03F03273-4BCB-ED11-BC97-000C2920F782' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_bm_sozlesmeler_key: 0,
			CrmDataTable_bm_sozlesmeler_options: {
				searchAttributes: ["bm_name", "bm_firmaidname"],
				filterParent: [
					{ 'name': 'bm_firmaid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '03F03273-4BCB-ED11-BC97-000C2920F782',
				views: [
					{ 'viewName': 'Etkin Sözleşmeler', 'savedQueryId': '246F9D6A-C8B5-4612-8867-07A9E6208712' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_bm_rakipunite_key: 0,
			CrmDataTable_bm_rakipunite_options: {
				searchAttributes: ["bm_serino"],
				filterParent: [
					{ 'name': 'bm_gercekmusteriid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'CF46E8A2-E891-4152-97FD-94A7B18C8501',
				views: [
					{ 'viewName': 'İlişkili Görünüm', 'savedQueryId': 'CF46E8A2-E891-4152-97FD-94A7B18C8501' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_bm_otomasyonprojesi_key: 0,
			CrmDataTable_bm_otomasyonprojesi_options: {
				searchAttributes: ["bm_name", "bm_projekodu", "bm_firmaname"],
				filterParent: [
					{ 'name': 'bm_firma', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'A6E2710D-5832-4EC8-828C-D7D8FF88230E',
				views: [
					{ 'viewName': 'İlişkili Görünüm', 'savedQueryId': 'A6E2710D-5832-4EC8-828C-D7D8FF88230E' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-ban',
					command: () => { this.OnDevreDisiBirak(); },
					visible: () => !this.isDisabled
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Randevu Ekle',
					icon:'pi pi-fw pi-calendar-plus',
					command: () => { this.OnRandevuEkle(); },
					visible: () => !this.isDisabled
				},
				{
					label:'Tel. Gör. Ekle',
					icon:'pi pi-fw pi-phone',
					command: () => { this.OnTelefonGorusmesiEkle(); },
					visible: () => !this.isDisabled
				},
				{
					label:'Sat. Projesi Ekle',
					icon:'pi pi-fw pi-money-bill',
					command: () => { this.SatisProjeEkle(); },
					visible: () => !this.isDisabled
				},
				{
					label:'Rakip Tezgah Ekle',
					icon:'pi pi-fw pi-money-bill',
					command: () => { this.RakipTezgahEkle(); },
					visible: () => !this.isDisabled
				},
			],

			products: [],
            carouselResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: "768px",
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
    },
    mounted() {
		/*
        this.productService.getProductsTezgah().then((products) => {
            this.products = products;
        });
		*/
    },
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.isYazmaYetkisiVarmi == false) {
				return true;
			}

			if (this.mainData["statecode"]) {
				if (this.mainData["statecode"] == 1) {
					return true;
				}
			}

			return false;
		},
		isYazmaYetkisiVarmi: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == true;
					}
				}
			}

			return false;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		isServisTalebiYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Servis Talepleri');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isYedekParcaTeklifSiparis(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Y.P. Teklif / Sipariş');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isSozlesmelerYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Sözleşmeler');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isTezgahlarYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Tezgahlar');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isSiparislerYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Firmalar');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isSatisProjeleriYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Satış Projeleri');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		SM_Bolge: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bolge');
		},
		SM_FirmaTipi: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'customertypecode');
		},
		SM_IlgiliMarka: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullanicitipi');
		},
		SM_Kategori: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'accountcategorycode');
		},
		SM_Endustri: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'industrycode');
		},
		SM_KullandigiTezgah: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullandigitezgah');
		},
		SM_Ulke: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ulke');
		},
		SM_Sehir: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_sehir');
		},
		SM_Sehir2: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_adres2sehir');
		},
		SM_KullandigiYag: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullandigiyag');
		},
		SM_3dPotansiyeli: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_3dpotansiyeli', 'bool');
		},
		SM_IstanbulAnadoluAvrupa: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_istanbulandavr');
		},
		SM_SatisProjeleriDurum: function () {
			let data = this.$store.getters.getStringMapByEntityId(3, "statecode");
			const tumu = {
				"AttributeName": "statecode",
				"AttributeValue": -1,
				"Value": "Tümü",
				"DisplayOrder": 5,
				"ObjectTypeCode": 3
			};
			data.push(tumu);
            return data;
        },
		SM_paymenttermscode: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'paymenttermscode');
		},
		yetkiliKisiSelected: {
			get: function () {
				debugger;
				if (this.mainData["yetkiliKisiId"]) {
					return { "Value": this.mainData["yetkiliKisiId"], "Name": this.mainData["yetkiliKisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				debugger;
				console.log(newValue);

				if (newValue == null) {
					this.mainData["yetkiliKisiId"] = null;
					this.mainData["yetkiliKisiName"] = null;
				}
				else {
					this.mainData["yetkiliKisiId"] = newValue.Value;
					this.mainData["yetkiliKisiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;
					let data = await this.crmService.getFirmaById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}

					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.mainData["entityId"] = this.entityId;
						this.ozetData = data.ozet[0];

						const pageHistoryItem = { entityLetter: 'Frm', entityName: 'account', ID: this.mainData.ID, title: this.mainData.firmaAdi };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

						if (this.mainData["yetkiliKisiId"]) {
							this.$refs.entity_yetkilikisi.setDefaultData({ "Value": this.mainData["yetkiliKisiId"], "Name": this.mainData["yetkiliKisiName"] });
						}

						this.CrmDataTable_contact_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_contact_key++;

						this.CrmDataTable_opportunity_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_opportunity_key++;

						this.CrmDataTable_salesorder_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_salesorder_key++;

						this.CrmDataTable_bm_unite_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_unite_key++;

						this.CrmDataTable_appointment_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_appointment_key++;

						this.CrmDataTable_phonecall_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_phonecall_key++;

						this.CrmDataTable_bm_yedekparcateklifsiparis_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_yedekparcateklifsiparis_key++;

						this.CrmDataTable_incident_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_incident_key++;

						this.CrmDataTable_serviceappointment_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_serviceappointment_key++;

						this.CrmDataTable_bm_sozlesmeler_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_sozlesmeler_key++;

						this.CrmDataTable_bm_rakipunite_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_rakipunite_key++;

						this.CrmDataTable_bm_otomasyonprojesi_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonprojesi_key++;


						
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;

			if (this.mainData.telefon1 != null && this.mainData.telefon1.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen Telefon 1 numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			if (this.mainData.telefon2 != null && this.mainData.telefon2.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen Telefon 2 numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			if (this.mainData.fax != null && this.mainData.fax.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen Faks numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getFirmaUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('account', this.entityId);
			if (window.opener) {
				window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
			}
        },
		OnDevreDisiBirak() {
			const postData = {
				logicalName: 'account',
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		OnRandevuEkle(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'appointmentnew', params: { id: this.entityId, name: this.mainData.firmaAdi } });
			window.open(routeData.href, '_blank', features);
		},
		OnClickWebSite() {
			if (this.mainData["webSitesi"] != null) {
				if (this.mainData["webSitesi"] != "") {
					let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';
					window.open(this.mainData["webSitesi"], '_blank', features);
				}
			}
		},
		IlgiliKisiEkle(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'contactnewfirma', params: { id: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		SatisProjeEkle(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'opportunitynewfirma', params: { id: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		RakipTezgahEkle(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_rakipunite_withAccountId', params: { accountid: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		OnTelefonGorusmesiEkle() {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'phonecallnew', params: { id: this.entityId, name: this.mainData.firmaAdi } });
			window.open(routeData.href, '_blank', features);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Firmalar');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaAdi: {
					required : helpers.withMessage('Firma Adı alanı dolu olmalıdır!', required),
				},
				telefon1: {
					required : helpers.withMessage('Telefon alanı dolu olmalıdır!', required),
				},
				epostaAdresi1: {
					email : helpers.withMessage('Geçerli bir mail adresi giriniz!', email),
				},
				bolgeId: {
					required : helpers.withMessage('Bölge alanı dolu olmalıdır!', required),
				},
				sehirId: {
					required : helpers.withMessage('Şehir alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.product-item {
    .product-item-content {
        border: 1px solid var(--surface-d);
        border-radius: 3px;
        margin: 0.3rem;
        text-align: center;
        padding: 2rem 0;
    }
    .product-image {
        height: 150px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

</style>
